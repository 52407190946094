import thankssponsorsImage from '../assets/thankssponsors.png';
import olandsbankImage from '../assets/olandsbank.png';
import icaborgholmImage from '../assets/icaborgholm.png';
import borgholmskommunImage from '../assets/borgholmskommun.png';
import nissesfiskImage from '../assets/nissesfisk.png';
import chassitImage from '../assets/chassit.png';
import byggmaxImage from '../assets/byggmax.png';
import borgholmshandelstradgardImage from '../assets/borgholmshandelstradgard.png';
import './Sponsors.scss';

function Sponsors() {
    return (
        <div className="Sponsors">
            <div className='thankssponsors-container'>
                <img src={thankssponsorsImage} alt='Tack till våra sponsorer' />
            </div>
            <ul className='sponsors-logo-list'>
                <li>
                    <img src={olandsbankImage} alt='Ölands Bank' />
                </li>
                <li>
                    <img src={icaborgholmImage} alt='ICA Supermarket Borgholm' />
                </li>
                <li>
                    <img src={borgholmskommunImage} alt='Borgholms Kommun' />
                </li>
                <li>
                    <img src={nissesfiskImage} alt='Nisses fisk' />
                </li>
                <li>
                    <img src={chassitImage} alt='ChassIT' />
                </li>
                <li>
                    <img src={byggmaxImage} alt='Byggmax' />
                </li>
                <li>
                    <img src={borgholmshandelstradgardImage} alt='Borgholm Handelsträdgård' />
                </li>
            </ul>
            <div className='sponsors-text-container'>
                <ul className='sponsors-text-list'>
                    <li>ADT digitaltryck</li>
                    <li>Addition</li>
                    <li>Bomans Torghandel</li>
                    <li>Borgens konserver</li>
                    <li>BP-Rör</li>
                    <li>Danielssons Herrmode</li>
                    <li>ELON Borgholm</li>
                    <li>Hantverket</li>
                    <li>ICA Köpingsvik</li>
                    <li>Karl:Es Färg</li>
                    <li>OK P-O Krussels Bensin & Livs</li>
                    <li>Sallong Birgitta</li>
                    <li>Syskrinet</li>
                </ul>
                <ul className='sponsors-text-list'>
                    <li><b>Välkommen att äta på:</b></li>
                    <li>Borgholm Kök & Bar</li>
                    <li>Nya Conditioriet Borgholm</li>
                    <li>Stenugnsbageriet Olof Borgholm</li>
                </ul>
            </div>
        </div>
    );
}

export default Sponsors;
