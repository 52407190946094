import svg from '../assets/loader.svg';
import errorGif from '../assets/error.gif';

interface Props {
    error: MajError;
}

const Spinner = () => {
    return (
        <div className="Spinner">
            <img src={svg} alt="Hämtar data..."></img>
            <p>Loading...</p>
        </div>
    );
}

const Loading = (props: Props) => {
    return (
        <>{props.error.code ? (<><img src={errorGif} alt="Laddar.." /><p>{props.error.string}</p></>) : (<Spinner />)}</>
    );
}

interface MajError {
    code: number | undefined;
    string: string | undefined;
}

export { Spinner, Loading, type MajError };
