import { Link } from 'react-router-dom';
import './NavBarBottom.scss';

function NavBarBottom() {
    return (
        <div className="NavBarBottom">
            <ul>
                <li className='left'><Link to="/start"><div className='navbar-linkbox'><p>Start</p></div></Link></li>
                <li className='middle'><Link to="/artists"><div className='navbar-linkbox'><p>Konstnärer</p></div></Link></li>
                <li className='right'><Link to="/event"><div className='navbar-linkbox'><p>Kartan</p></div></Link></li>
            </ul>
        </div>
    );
}

export default NavBarBottom;
