import './Footer.scss';

function Footer() {
    return (
        <div className="Footer">
            <p>Föreningen KonstMajrundan 2024</p>
            <span><a href="mailto:annikaliepa@gmail.com">annikaliepa@gmail.com</a></span>
        </div>
    );
}

export default Footer;
