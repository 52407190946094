const mockGetArtists = () => {
    return Promise.resolve({
        data: 
        [{
            _id: "5cbc513b343fed691938ea15",
            name: 'Carl-Olof Damm',
            craft: 'Keramik/Skulptur',
            textbody: ' <p>Född 1949 i Västervik Arbetar i lera sedan 1973, i Borgholm sedan 1985, där jag delar en verkstad med Annika Liepa. Vi kallar oss Handkraft.</p>\n' +
                '<p>Jag gör skulpturer i stengods, människor, djur och andra varelser från verklighet eller fantasi inför livets mysterium.\n' +
                'Offentliga utsmyckningar i bland annat Borgholms bibliotek och journalisthögskolan i Kalmar, men också små miniatyrer. Vaser och objekt med bilder, en historia ur verklighet eller fantasi, landskap, skepp, människor, himmel och hav.</p>',
            active: true,
            craft_image_url: 'f048bee848da6210ace1010761076696ae0c',
            artist_image_url: '1cdd7693f1088e1395106e0965b7682b10f',
            exhibitionaddress: {
                _id: "5cee8e2c20a11b0302ff6508",
                street: 'Sandgatan 20/18',
                town: 'Borgholm',
                active: true,
                lat: 56.881666,
                lon: 16.657934
            }
        }, {
            _id: "5cbc513b343fed691938ea16",
            name: 'Carl-Olof Damm',
            craft: 'Keramik/Skulptur',
            textbody: ' <p>Född 1949 i Västervik Arbetar i lera sedan 1973, i Borgholm sedan 1985, där jag delar en verkstad med Annika Liepa. Vi kallar oss Handkraft.</p>\n' +
                '<p>Jag gör skulpturer i stengods, människor, djur och andra varelser från verklighet eller fantasi inför livets mysterium.\n' +
                'Offentliga utsmyckningar i bland annat Borgholms bibliotek och journalisthögskolan i Kalmar, men också små miniatyrer. Vaser och objekt med bilder, en historia ur verklighet eller fantasi, landskap, skepp, människor, himmel och hav.</p>',
            active: true,
            craft_image_url: 'f048bee848da6210ace1010761076696ae0c',
            artist_image_url: '1cdd7693f1088e1395106e0965b7682b10f',
            exhibitionaddress: {
                _id: "5cee8e2c20a11b0302ff6508",
                street: 'Sandgatan 20/18',
                town: 'Borgholm',
                active: true,
                lat: 56.881666,
                lon: 16.657934
            }
        }]
    });
}

const mockGetArtist = (id: string | undefined) => {
    return Promise.resolve({
        data: {
            _id: "5cbc513b343fed691938ea15",
            name: 'Carl-Olof Damm',
            craft: 'Keramik/Skulptur',
            textbody: ' <p>Född 1949 i Västervik Arbetar i lera sedan 1973, i Borgholm sedan 1985, där jag delar en verkstad med Annika Liepa. Vi kallar oss Handkraft.</p>\n' +
                '<p>Jag gör skulpturer i stengods, människor, djur och andra varelser från verklighet eller fantasi inför livets mysterium.\n' +
                'Offentliga utsmyckningar i bland annat Borgholms bibliotek och journalisthögskolan i Kalmar, men också små miniatyrer. Vaser och objekt med bilder, en historia ur verklighet eller fantasi, landskap, skepp, människor, himmel och hav.</p>',
            active: true,
            craft_image_url: 'f048bee848da6210ace1010761076696ae0c',
            artist_image_url: '1cdd7693f1088e1395106e0965b7682b10f',
            exhibitionaddress: {
                _id: "5cee8e2c20a11b0302ff6508",
                street: 'Sandgatan 20/18',
                town: 'Borgholm',
                active: true,
                lat: 56.881666,
                lon: 16.657934
            }
        }
    });
}

const mockGetArtistGallery = (id: string) => {
    return Promise.resolve({
        data: { artist_id: id, images_ids: [{ image_id: 'mock' }, { image_id: 'mock' }, { image_id: 'mock' }] }
    });
}

export { mockGetArtists, mockGetArtist, mockGetArtistGallery };

