import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.scss';

function NavBar() {
    return (
        <div className="NavBar">
            <ul>
                <li><Link to="/start"><div className='navbar-linkbox'><p>Start</p></div></Link></li>
                <li><Link to="/artists"><div className='navbar-linkbox'><p>Konstnärer</p></div></Link></li>
                <li><Link to="/event"><div className='navbar-linkbox'><p>Kartan</p></div></Link></li>
            </ul>
        </div>
    );
}

export default NavBar;
