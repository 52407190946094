import { Gallery } from '../api/gallery'
import { useEffect, useState } from "react";
import { getArtistGallery } from '../api/api';

import './ArtistGallery.scss';

import mockImage from '../assets/mock_artist-image.jpg'

const USE_MOCK = process.env.REACT_APP_USE_MOCK;


const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
const API_HOST = process.env.REACT_APP_API_HOST;
const GALLERY_API_PATH = `${HTTP_PROTOCOL}://${API_HOST}/api`;

interface Props {
    artist_id: string;
}

const ArtistGallery = (props: Props) => {
    const [gallery, setGallery] = useState<Gallery | undefined>();

    const renderedGalleryPhotos = gallery?.images_ids?.map((image_id: string) => {
        return (
            <div key={image_id} className="gallery-photo-container" onClick={() => window.open(`${GALLERY_API_PATH}/artist/${props.artist_id}/gallery/image/${image_id}`)}><img src={USE_MOCK ? mockImage : `${GALLERY_API_PATH}/artist/${props.artist_id}/gallery/image/${image_id}`} alt='Bild på konstverk' /></div>
        );
    });

    useEffect(() => {
        getArtistGallery(props.artist_id)
            .then((response) => {
                setGallery(response.data);
            })
            .catch((error) => console.error(error));
    }, [props.artist_id]);

    return (
        <>
            {
                (gallery?.images_ids?.length !== 0) &&
                (
                    <div className="ArtistGallery" >
                        <h3>Urval av Alster</h3>
                        <div className='gallery-flex-container'>
                            {gallery ? renderedGalleryPhotos : (<div></div>)}
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default ArtistGallery;