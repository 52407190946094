import { Link } from 'react-router-dom'

import Sponsors from '../components/Sponsors';

import '../page.scss'
import './StartPage.scss';

import posterThumbImage from '../assets/poster.png';
import flowerImage from '../assets/blomma.png';

function StartPage() {
    return (
        <div className="StartPage">
            <div className="pageItem">
                <div className="pageItem-header">
                    <h2>KonstMajrundan under ölands Skördefest 2024</h2>
                </div>
                <div className="pageItem-body news-body">
                    <p>Välkommen till KonstMajrundans öppna ateljéer under Ölands Skördefest den 25:e till 29:e september.</p>

                    <div className='atelier-section'>
                        <h3>Öppna ateljéer under Skördfesten 2024:</h3>
                        <ul className="atelier-list">
                            <li><Link to="/artists/5ceeac117d0d7a05812dccd6">Birgitta Augustine</Link> i Karlevi
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-17</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cbc704a27d88f6a3e4f733d">Göran Petersson</Link> i Sönnerborg<br></br>
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-17</li><li>, Sön 11-17</li></ul></li>
                            <li><Link to="/artists/5cebd32f67f5d270cd741593">Ingela Beijer</Link> i Kvistorp<br></br>
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-17</li><li>, Sön 11-16</li></ul></li>
                            <li><Link to="/artists/5cebd13e67f5d270cd74158d">Tina Stellema</Link> i Sättra
                                <ul><li>Tors 11-17</li><li>, Fre 10-24</li><li>, Lör 10-21</li><li>, Sön 10-17</li></ul></li>
                            <li><Link to="/artists/5cebd36767f5d270cd741595">Monica Alskans</Link> i Sättra
                                <ul><li>Tors stängt</li><li>, Fre 12-24</li><li>, Lör 11-20</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/660feebe4470167d95bee4d2">Ed Whittaker</Link> i Sättra
                                <ul><li>Tors 11-17</li><li>, Fre 11-24</li><li>, Lör 11-20</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cbc6f8827d88f6a3e4f733b">Roland Gross</Link> i Rälla Tall
                                <ul><li>Tors stängt</li><li>, Fre 10-17</li><li>, Lör 10-17</li><li>, Sön 10-12</li></ul></li>
                            <li><Link to="/artists/6616b9c88c0daf0c9c160804">Helle Kvamme</Link> i Borgholm
                                <ul><li>Tors stängt</li><li>, Fre 10-17</li><li>, Lör 10-17</li><li>, Sön 10-12</li></ul></li>
                            <li><Link to="/artists/5cbc513b343fed691938ea15">Carl-Olof Damm</Link> i Borgholm
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-21</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cebd05767f5d270cd741589">Anna Hertzberg/Holgersson</Link> i Borgholm
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-21</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cbc5288343fed691938ea17">Susanne Nyholm</Link> i Borgholm
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-21</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cbc685727d88f6a3e4f7335">Sixten Carlsson</Link> i Borgholm
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-21</li><li>, Sön 11-15</li></ul></li>
                            <li><Link to="/artists/5cbc51a3343fed691938ea16">Annika Liepa</Link> i Borgholm
                                <ul><li>Tors 11-17</li><li>, Fre 11-21</li><li>, Lör 11-21</li><li>, Sön 11-15</li></ul></li>
                        </ul>
                        <br></br>
                    </div>
                    <p>
                        KonstMajrundans medlemar hittar du också <Link to="/artists">här</Link>
                    </p>
                    <div><img className='flowerImage' src={flowerImage} alt='bild på blomma' /></div>
                </div>
            </div>
            <div className='pageItem'>
                <Sponsors />
            </div>
        </div>
    );
}

export default StartPage;
