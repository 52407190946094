import axios from 'axios';
import { mockGetArtists, mockGetArtist, mockGetArtistGallery } from '../api/mock';

const USE_MOCK = process.env.REACT_APP_USE_MOCK;

const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
const API_HOST = process.env.REACT_APP_API_HOST;
const API_PORT = process.env.REACT_APP_API_PORT;
const API_PATH = `${HTTP_PROTOCOL}://${API_HOST}:${API_PORT}/api`;
const GALLERY_PORT = process.env.REACT_APP_GALLERY_PORT;
const GALLERY_API_PATH = `${HTTP_PROTOCOL}://${API_HOST}:${GALLERY_PORT}/api`;

const instance = axios.create({
    baseURL: API_PATH
});

const galleryInstance = axios.create({
    baseURL: GALLERY_API_PATH
})

const getArtists = () => USE_MOCK ? mockGetArtists() : instance.get('/artists'); 
const getArtist = (id: string | undefined) =>  USE_MOCK ? mockGetArtist(id) : instance.get(`/artists/${id}`);
const getArtistGallery = (id: string) => USE_MOCK ? mockGetArtistGallery(id) : galleryInstance.get(`/artist/${id}/gallery`);

export { HTTP_PROTOCOL, API_HOST, API_PORT, API_PATH, instance, getArtists, getArtist, getArtistGallery };
