import React, { useEffect, useState } from "react";
import { getArtists } from "../api/api";
import OlMap from "./Map/OlMap";
import './ArtistsMap.scss';
import { Artist } from "../api/artist";
import { useAppDispatch } from "../redux/hooks";
import { setSelectedArtistId } from "../redux/mapSlice";

const ArtistsMap: React.FC = () => {
    const dispatch = useAppDispatch();
    const [artists, setArtists] = useState<Array<Artist>>();
    const renderedArtistsList = artists?.map((artist) => {
        if (!artist.exhibitionaddress.active) return false;
        else return (<li key={artist._id}><button onClick={() => {
            if (artist.exhibitionaddress.lat && artist.exhibitionaddress.lon) return handleArtistSelectClick(artist._id);
        }}>{artist.name}</button></li>);
    });
    const handleArtistSelectClick = (id: string) => {
        dispatch(setSelectedArtistId(id));
    };
    useEffect(() => {
        getArtists()
            .then((response) => {
                setArtists(response.data);
            })
            .catch((error) => console.error(error));
    }, []);
    return (
        <div className="ArtistsMap">
            <ul>
                {renderedArtistsList}
            </ul>
            <OlMap artists={artists!} />
        </div>
    );
};

export default ArtistsMap;
