import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Banner from './components/Banner';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import NavBarBottom from './components/NavBarBottom';
import ArtistsPage from './pages/ArtistsPage';
import StartPage from './pages/StartPage';
import ArtistPage from './pages/ArtistPage';
import EventPage from './pages/EventPage';
import './App.scss';
import './page.scss';

function App() {
  return (
    <div className="App">
      <Router>
        <Banner />
        <div className='navbar-topper'>
          <NavBar />
        </div>
        <div className="page">
          <Routes>
            <Route path="/start" element={<StartPage />} />
            <Route path="/artists/:id" element={<ArtistPage />} />
            <Route path="/artists" element={<ArtistsPage />} />
            <Route path="/event" element={<EventPage />} />
            <Route
              path="/"
              element={<Navigate to="/start" replace />}
            />
          </Routes>
        </div>
        <div className='navbar-bottomer'>
          <NavBarBottom />
        </div>
      </Router>
      <Footer />
      <div className='navbar-bottomer-fixer' />
    </div>
  );
}

export default App;
