import { createSlice } from '@reduxjs/toolkit';

const mapSlice = createSlice({
    name: 'map',
    initialState: {
        selectedArtistId: undefined
    },
    reducers: {
        setSelectedArtistId: (state, action) => {
            state.selectedArtistId = action.payload; 
        },
        clearSelectedArtistId: (state) => {
            state.selectedArtistId = undefined;
        }
    }
});

export const { setSelectedArtistId, clearSelectedArtistId } = mapSlice.actions;
export default mapSlice.reducer;