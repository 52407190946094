import ArtistsList from '../components/ArtistsList';
import './ArtistsPage.scss';

function ArtistsPage() {
    return (
        <div className="ArtistsPage">
            <ArtistsList />
        </div>
    );
}

export default ArtistsPage;
